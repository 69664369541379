.cls1 {
  isolation: isolate;
}
.cls2 {
  fill: url(#linear-gradient);
}
.cls3 {
  fill: url(#linear-gradient-2);
}
.cls4 {
  mix-blend-mode: multiply;
  fill: url(#linear-gradient-3);
}
.cls5 {
  fill: url(#linear-gradient-4);
}
