.container {
  padding: 20px 10px 0;
}
@media print {
  .container {
    display: none !important;
  }
}

.contactsWrapper {
  text-align: center;
}

.contacts {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 959.95px) {
  .contacts {
    display: inline-block;
  }
}

.contactsItem {
  display: flex;
  align-items: center;
  margin: 0 32px;
  text-align: left;
}

.contactsIcon {
  margin-right: 16px;
}

.social {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.socialItem {
  list-style: none;
}
@media (min-width: 600px) {
  .socialItem {
    margin: 8px;
  }
}

.legal {
  text-align: center;
}

.copyright {
  text-align: center;
}
