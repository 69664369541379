.ieFixContainer {
  display: flex;
  flex-direction: column;
}
.container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.content {
  flex: 1 0 auto;
}
