.accountDetails {
  padding-top: 8px;
}
.accountDetailsButton {
  text-transform: none;
  width: 100%;
  justify-content: left;
}
.accountDetailsName {
  font-size: 0.85rem;
  color: inherit;
}
.accountDetailsEmail {
  font-size: 0.75rem;
  color: inherit;
}
.accountDetailsIcon {
  margin-right: 0;
  position: absolute;
  right: 0;
}

.accountButton {
  text-align: center;
}

.menuLink {
  text-decoration: none;
  color: inherit;
}
