.container {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 8px;
  margin-right: 8px;

  max-width: 1400px;
}
@media (min-width: 600px) {
  .container {
    margin-left: 16px;
    margin-right: 16px;
  }
}
@media (min-width: 960px) {
  .container {
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 32px;
    margin-right: 32px;
  }
}
@media (min-width: 1280px) {
  .container {
    margin-top: 32px;
    margin-bottom: 32px;
    width: calc(100% - 64px); /* To have a minimum of the same margins as in "md" */
    margin-left: auto;
    margin-right: auto;
  }
}

.headline {
  margin-bottom: 0;
}
.title {
  margin-bottom: 0;
}
.divider {
  margin-bottom: 16px;
}
