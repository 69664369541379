.container {
  position: fixed;
  top: 90px;
  right: 0;
  z-index: 1101;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media (min-width: 1280px) {
  .container {
    top: 140px;
  }
}
@media print {
  .container {
    display: none;
  }
}

.promo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  border: none;
  padding: 0.2em 0;
  max-width: 90vw;
  font-size: 1em;
  line-height: 1em;
  border-radius: 1.2em 0 0 1.2em;
}

.promoLabel {
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}

.promoLabelClose {
  display: none;
}

.promoButton {
  display: inline-flex;
  margin: 0 0.2em;
  border: 0;
  padding: 0;
  height: auto;
  color: inherit;
  background: transparent;
  cursor: pointer;
  opacity: 0.6;
}
.promoButton:hover {
  opacity: 1;
}

.starsAlign {
  display: inline-block;
}
.startComment {
  padding-left: 8px;
}
