.toolbar {
  height: 70px;
}
@media print {
  .toolbar {
    display: none !important;
  }
}

.menuButton {
  margin-left: -12;
}
@media (min-width: 600px) {
  .menuButton {
    margin-right: 20;
  }
}
@media (min-width: 1280px) {
  .menuButton {
    display: none;
  }
}

.main {
  flex: 1;
  display: flex;
}

.brandLink {
  flex: 0;
  color: inherit;
  text-decoration: none;
}
.brandWrapper {
  display: flex;
  align-items: center;
  margin-top: -2px;
}

.brandTexts {
  transform: translateY(30%);
  display: flex;
  flex-direction: column;
}
@media (max-width: 959.95px) {
  .brandTexts {
    display: none;
  }
}
.brandName {
  font-family: brand;
  white-space: nowrap;
}
.brandSlogan {
  transform: translateY(-40%);
  white-space: nowrap;
  font-size: 0.7em;
}
.brandPro {
  margin: auto;
  font-size: 1.5em;
}
@media (max-width: 959.95px) {
  .brandPro {
    display: none;
  }
}
.brandLogo {
  height: 30px;
}
@media (min-width: 959.95px) {
  .brandLogo {
    margin: 0 15px 0 0;
  }
}

.accountGroup {
  justify-content: flex-end;
}

.buttonIcon {
  margin-right: 8px;
}

.menu {
  min-height: auto;
  justify-content: center;
  z-index: var(--mui-zIndex-appBar);
}
@media (max-width: 1279.95px) {
  .menu {
    display: none;
  }
}
.menuCard {
  max-width: 350;
  overflow-y: auto;
}

.menuTabRoot:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.menuTabWrapper {
  display: block;
  height: 100%;
}
.menuLink {
  display: flex;
  padding: 0 32px;
  height: 100%;
  width: 100%;

  align-items: center;
  justify-content: center;

  color: inherit;
  text-decoration: none;
}
.link {
  color: inherit;
  text-decoration: none;
}
@media (max-width: 959.95px) {
  .userName {
    display: none;
  }
}
@media (max-width: 959.95px) {
  .phone {
    display: none;
  }
}
