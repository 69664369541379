.noneMargin {
  margin-top: 0;
  margin-bottom: 0;
}
.denseMargin {
  margin-top: 0;
  margin-bottom: 8px;
}
.normalMargin {
  margin-top: 8px;
  margin-bottom: 16px;
}
