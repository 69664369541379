.imageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.imageLink {
  display: block;
  width: 100%;
}
@media (min-width: 960px) {
  .imageLink {
    margin-right: auto;
    margin-left: 0;
    max-width: 900px;
  }
}

.image {
  display: block;
  width: 100%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 8px auto 40px;
  text-align: center;

  padding: 0 24px;
  max-width: calc(430px + 48px);
}
@media (min-width: 960px) {
  .info {
    margin: 0 auto 0 0;
    padding: 0 50px;
    max-width: calc(430px + 100px);
    text-align: left;
  }
}
@media (min-width: 1280px) {
  .info {
    padding: 0 100px;
    max-width: calc(430px + 200px);
  }
}

@media (min-width: 960px) {
  .reverse {
    margin: 0 0 0 auto;
    margin-right: 0;
    margin-left: auto;
  }
}
