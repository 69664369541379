.galleryContainer {
  position: relative;
}

.madeInFrance {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
}
@media (min-width: 600px) {
  .madeInFrance {
    width: 200px;
  }
}

.scrollDownButton {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);

  background-color: #ffffff;
  box-shadow:
    0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.scrollDownButton:hover {
  background-color: #f5f5f5;
}

.scrollDownIcon {
  font-size: 2rem;
}

.title {
  margin: 40px 0 24px;
}
