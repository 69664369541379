.container {
  position: relative;
  height: 60vh;
}
@media (min-width: 600px) {
  .container {
    height: 75vh;
  }
}

.slide {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.informationContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 8px;
  padding-bottom: 65px;
}
@media (min-width: 600px) {
  .informationContainer {
    padding: 16px;
    padding-bottom: 40px;
  }
}

.information {
  text-align: center;
}
@media (min-width: 960px) {
  .information {
    margin: auto;
    max-width: 1400px;
  }
}

.text {
  text-shadow:
    1px 0px 5px #000,
    -1px 0px 5px #000,
    0px 1px 5px #000,
    0px -1px 5px #000;
  color: white;
}
.text :global(.MuiTypography-root) {
  color: white;
}

@media (max-width: 959.95px) {
  .title {
    font-size: 1.3rem;
  }
}

.priceSup {
  color: white;
}

.nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
}
.prev {
  left: 8px;
}
.next {
  right: 8px;
}
.navIcon {
  filter: drop-shadow(0px 0px 1.5px #000);
}

.indicators {
  position: absolute;
  bottom: 24px;
  right: 8px;
  color: white;
}
@media (min-width: 600px) {
  .indicators {
    bottom: 8px;
  }
}

.radioIcon {
  filter: drop-shadow(0px 0px 1.5px #000);
}
