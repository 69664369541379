.title {
  margin: 32px 0 16px;
}
.imageContainer {
  display: block;
  margin: 16px 0;
}
.image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}
.imageCaption {
  display: block;
}
